import team1 from "../img/team1.png";
import team2 from "../img/team2.png";
import team3 from "../img/team3.png";
import team4 from "../img/team4.png";
import team5 from "../img/team5.png";
import team6 from "../img/team6.png";

export default [
  {
    id: 1,
    image: team1,
    name: "Alpha Smilodon",
    role: "Founder",
  },
  {
    id: 2,
    image: team2,
    name: "Crypto Bison",
    role: "Investor/Co-Founder",
  },
  {
    id: 3,
    image: team3,
    name: "Matt Paws",
    role: "Blockchain & Web3 Development",
  },
  {
    id: 4,
    image: team4,
    name: "Mutant Saber",
    role: "Artist",
  },

  {
    id: 5,
    image: team5,
    name: "Leopardus Max",
    role: "PR",
  },
  {
    id: 6,
    image: team6,
    name: "Skittles Cougar",
    role: "Social Media & Marketing",
  },
];
