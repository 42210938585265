import { Link } from "react-router-dom";

function Community() {
  return (
    <section className="px-8 md:max-w-6xl py-20 mx-auto w-full">
      <div className="space-y-4 flex flex-col items-center">
        <h2 className="text-center text-xl md:text-3xl">
          Click here to read the roadmap
        </h2>
        <a
          className="uppercase bg-blue px-10 py-4 rounded-md inline-block font-semibold border border-white"
          href="/roadmap"
        >
          Roadmap
        </a>
        <p className="text-justify md:text-center text-sm md:text-lg">
          Our Roadmap has two Episodes (The Hype Sale & The Utility). Staking is
          already live! Stake your NFTs to get BITE Tokens
        </p>
        <p className="text-justify md:text-center text-sm md:text-lg">
          Don't miss out the hype sale. Randomly selected wallets to win Mutant
          Ape, Bored Kennel, Hape Beast, Cool Pets & an NFT Necklace
        </p>
      </div>
    </section>
  );
}

export default Community;
