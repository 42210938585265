import logo from "../img/logo.png";
import twitter from "../img/twitter.svg";
import tiktok from "../img/tiktok.svg";
import discord from "../img/discord.svg";
import instagram from "../img/instagram.svg";

function Footer() {
  return (
    <footer className="px-12 md:max-w-7xl py-5 mx-auto w-full">
      <div className="flex flex-col items-center space-y-4">
        <figure>
          <img className="h-20" src={logo} alt="" />
        </figure>
        <ul className="flex space-x-3">
          <a href="https://twitter.com/snkysabertooth" target="_blank">
            <img className="h-5" src={twitter} alt="Instagram" />
          </a>

          <a
            href="https://www.instagram.com/sneakysabertooth_/"
            target="_blank"
          >
            <img className="h-5" src={instagram} alt="Instagram" />
          </a>
          {/* <a href="#" target="_blank">
            <img className="" src={linkedin} alt="Instagram" />
          </a> */}
          <a href="https://www.tiktok.com" target="_blank">
            <img className="h-5" src={tiktok} alt="Instagram" />
          </a>
        </ul>
        <hr className="w-full border border-white" />
        <p className="text-center text-white">
          © 2022 Sneaky SaberTooth | All rights reserved
        </p>
      </div>
      <figure className="h-20 w-20 rounded-full bg-gray flex flex-col items-center justify-center fixed bottom-5 right-5 md:right-16 cursor-pointer">
        <a href="https://discord.gg/VfuvSNEnXb" target="_blank">
          <img className="h-10" src={discord} alt="" />
        </a>
      </figure>
    </footer>
  );
}

export default Footer;
