import React, { useState } from "react";
import mask from "../img/mask.png";
import twitter from "../img/twitter.svg";
import instagram from "../img/instagram.svg";

import team1 from "../img/team1.png";
import team2 from "../img/team2.png";
import team3 from "../img/team3.png";
import team4 from "../img/team4.png";
import team5 from "../img/team5.png";
import team6 from "../img/team6.png";

// Team Data

import teamData from "../handlers/teamData";

function Team() {
  const [teams, setTeams] = useState(teamData);

  return (
    <section className="px-8 md:max-w-6xl py-12 mx-auto w-full" id="team">
      <div className="flex flex-col items-center">
        <img src={mask} alt="" />
        <h2 className="text-3xl md:text-5xl -mt-28">The Saber Team</h2>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-10 py-14">
        <div className="flex flex-col items-center space-y-2 bg-gray p-5">
          <img src={team1} alt="" className="object-contain" />
          <h3 className="text-base md:text-xl text-center">Alpha Smilodon</h3>
          <p className="text-sm text-center">Founder</p>
          <div className="flex items-center space-x-2">
            <a href="https://twitter.com/SneakySaberTNFT">
              <img src={twitter} alt="" />
            </a>
            <a href="#">
              <img src={instagram} alt="" />
            </a>
          </div>
        </div>
        <div className="flex flex-col items-center space-y-2 bg-gray p-5">
          <img src={team2} alt="" className="object-contain" />
          <h3 className="text-base md:text-xl text-center">Crypto Bison</h3>
          <p className="text-sm text-center">Investor/Co-Founder</p>
          <div className="flex items-center space-x-2">
            <a href="#">
              <img src={twitter} alt="" />
            </a>
            <a href="#">
              <img src={instagram} alt="" />
            </a>
          </div>
        </div>
        <div className="flex flex-col items-center space-y-2 bg-gray p-5">
          <img src={team3} alt="" className="object-contain" />
          <h3 className="text-base md:text-xl text-center">Matt Paws</h3>
          <p className="text-sm text-center">Blockchain & Web3 Development</p>
          <div className="flex items-center space-x-2">
            <a href="#">
              <img src={twitter} alt="" />
            </a>
            <a href="https://www.instagram.com/gamerspree_21/">
              <img src={instagram} alt="" />
            </a>
          </div>
        </div>
        <div className="flex flex-col  items-center space-y-2 bg-gray p-5 ">
          <img src={team4} alt="" className="object-contain" />
          <h3 className="text-base md:text-xl text-center">Skittles Cougar</h3>
          <p className="text-sm text-center">Social Media & Marketing</p>
          <div className="flex items-center space-x-2">
            <a href="#">
              <img src={twitter} alt="" />
            </a>
            <a href="#">
              <img src={instagram} alt="" />
            </a>
          </div>
        </div>

        <div className="flex flex-col items-center space-y-2 bg-gray p-5 ">
          <img src={team5} alt="" className="object-contain" />
          <h3 className="text-base md:text-xl text-center">Mutant Saber</h3>
          <p className="text-sm text-center">Artist</p>
          <div className="flex items-center space-x-2">
            <a href="#">
              <img src={twitter} alt="" />
            </a>
            <a href="#">
              <img src={instagram} alt="" />
            </a>
          </div>
        </div>
        <div className="flex flex-col items-center space-y-2 bg-gray p-5">
          <img src={team6} alt="" className="object-contain" />
          <h3 className="text-base md:text-xl text-center">Saber Support</h3>
          <p className="text-sm text-center">Collab Manager</p>
          <div className="flex items-center space-x-2">
            <a href="#">
              <img src={twitter} alt="" />
            </a>
            <a href="#">
              <img src={instagram} alt="" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Team;
