import hero from "../img/hero.gif";
import { Link } from "react-router-dom";

function Hero() {
  return (
    <section className="px-8 md:max-w-6xl pt-32 md:pt-16 mx-auto w-full">
      <div className="flex items-center justify-center md:justify-between flex-wrap">
        <div className="space-y-2  md:space-y-4">
          <h2 className="text-2xl md:text-5xl text-center md:text-left">
            Welcome to
          </h2>
          <h2 className="text-2xl md:text-5xl text-center md:text-left">
            Sneaky Saber Tooth
          </h2>
          <Link
            to="/mint"
            className="uppercase bg-blue border border-white px-10 py-4 rounded-md hidden md:inline-block font-semibold"
          >
            mint here
          </Link>
          <div className="space-y-3">
            <div className="">
              <p className="text-center md:text-left">Price 0.15 Eth</p>
              {/* <p className="text-center md:text-left">
                Max Per Wallet 500(Open for a limted time)
              </p> */}
            </div>
            <p className="text-center hidden md:inline-block md:text-left">
              SST NFTs are a 100% Pre-Sale to avoid spam links.
            </p>
          </div>
        </div>
        <figure className="-mt-7 md:-mt-0">
          <img className="hero__image" src={hero} alt="" />
        </figure>
      </div>
    </section>
  );
}

export default Hero;
