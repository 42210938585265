export default [
  {
    id: 1,
    question: "WHEN WILL THE SNEAKY SABER TOOTH LAUNCH TAKE PLACE?",
    answer:
      "The Sneaky Saber Tooth launch date is to be announced. The NFT is 100% Pre-sale to avoid spam links & to have easy access that avoids tedious whitelist processes. Further updates will be revealed to the exclusive Discord community of early supporters.",
  },
  {
    id: 2,
    question: "HOW 'S THE SELLOUT PLAN?",
    answer:
      "The Sneaky Saber Tooth has a rewarding Phase I sellout plan; it offers one randomly selected whale wallet ownership to One Mutant Ape and a Host of Benefits for holders. No whitelist hassles as all of the NFTS are at Pre-Sale price. The buyer can make use of the Ethereum fluctuation and mint at any flexible juncture before the sellout.",
  },
  {
    id: 3,
    question: "WILL SNEAKY SABER TOOTH MAKE A SOUND INVESTMENT?",
    answer:
      "3D NFTs with staking utility in place are always 100X from pre-sale. Some holders also aspire at 10X or the least doubling the capital. We are a team passionate about Blockchain and have plans to live out of Metaverse by building, educating, and making a dent in the field of Metaverse and The World of Web3. With a team having a mindset of giving back at the pre-sale phase by Marque NFTs and marketing and passive income in place, do you think you cannot double your investment amount at least?",
  },
  {
    id: 4,
    question: "HOW MANY SNEAKY SABER TOOTHS ARE LANDING AT THE METAVERSE?",
    answer:
      "The Sneaky Saber Tooth is a riveting 3D collection of 10,000 NFT tigers living on the Ethereum blockchain.",
  },
  {
    id: 5,
    question: "WHAT BLOCKCHAIN IS THE PROJECT HOSTED ON?",
    answer: "Sneaky Saber Tooth is on the Ethereum Blockchain.",
  },
  {
    id: 6,
    question: "WHAT UTILITIES DO SNEAKY SABER TOOTHS HAVE?",
    answer:
      "Yes! Sneaky Saber Tooth is one of the only projects in the NFT space that offers a dope level of utilities.We have set 3 standards for our Utility: The Tight-Knit Tribe, The Multi-Realm Domination & The Financial Independence. Blaze through our detailed Roadmap if you haven't seen it yet.",
  },
];
