import React from "react";
import mask from "../img/mask.png";

function Bite() {
  return (
    <section className="px-12 md:max-w-7xl py-20 mx-auto w-full">
      <div className="flex flex-col items-center">
        <img src={mask} alt="" />
        <h2 className="text-2xl md:text-4xl -mt-28">What is $LGN?</h2>
      </div>
      <div className="mt-20 space-y-4">
        <h3 className="text-3xl font-semibold text-center">
          Everything About Logan Coin $LGN
        </h3>
        <p className="text-base md:text-lg text-center">
          All NFTs passively generate $LGN tokens that are usable for
          customization & upgrade in our secret game. $LGN token can be used for
          in-game content, secret levels, characters, and much more. LGN Holders
          get special perks with discounts on merch & virtual concerts,
          additionally some dope airdrops across multiple platforms.
          <br /> The Sneaky Saber Tooth will have open-world style gameplay in
          the metaverse. Our team shall create a new life in-game exploration in
          the Saber town where you can drive, go to shops, meet friends,
          schedule meetings; the possibilities are endless in the Saber World.
          Starting up businesses or shops makes it possible to earn passive
          income, with some in-game NFTs available to get IRL.
        </p>
      </div>
      <div className="mt-24 grid grid-cols-1 md:grid-cols-2 gap-x-20">
        <div className="space-y-14 md:space-y-28">
          <div className="flex space-x-4">
            <h3 className="text-3xl font-bold text-blue">01</h3>
            <p className="text-base md:text-xl">
              User can pay one time yearly rent towards residential/commercial
              contract in designated cities.
            </p>
          </div>
          <div className="flex space-x-3">
            <h3 className="text-3xl font-bold text-blue">02</h3>
            <p className="text-base md:text-xl">
              We will be launching a Play to Earn Game, Its a tropical island
              game where the tokens will be useful to upgrade stuff.
            </p>
          </div>
          <div className="flex space-x-3">
            <h3 className="text-3xl font-bold text-blue">03</h3>
            <p className="text-base md:text-xl">
              For Virtual concerts in the meta-verse we will require people to
              use the coins
            </p>
          </div>
          <div className="flex space-x-3">
            <h3 className="text-3xl font-bold text-blue">04</h3>
            <p className="text-base md:text-xl">
              We will be doing some brand collabs as well so that companies and
              other brands can use it
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center space-y-10 mt-10 md:mt-0">
          <div className="border-2 border-blue rounded-md p-5 space-y-10 w-full bite__box">
            <div className="space-y-1">
              <h2 className="text-xl font-semibold text-center">
                $LGN Supply:
              </h2>
              <p className="text-lg text-center">100,000,000</p>
            </div>
            <div className="space-y-1">
              <h2 className="text-xl font-semibold text-center">
                $LGN Smart Contract:
              </h2>
              <p className="text-base md:text-lg text-center">
              0x7E734dF8706df48b84bb23B94E46c8a13d19e6Dd
              </p>
            </div>
          </div>
          <div className="border-2 bite__box border-blue rounded-md p-5 space-y-10">
            <div>
              <p className="text-base md:text-lg text-center">
                $LGN is the utility token that fuels the Sneaky Saber ecosystem.
                It is NOT an investment and has NO economic value.
              </p>
            </div>
            <div>
              <p className="text-base md:text-lg text-center">
                Each Sneaky Saber is able to claim 10 $LGN tokens per day for a
                period of ten years. Sneaky Saber will produce total of
                3,650,000 $LGN tokens per year, over a period of ten years.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Bite;
