import React from "react";
import hero from "../img/manifesto.png";
import { Link } from "react-router-dom";

function Manifesto() {
  return (
    <section className="px-8 md:max-w-6xl md:py-10 pt-24 md:pt-24 mx-auto w-full">
      <div className="flex items-center justify-between">
        <div className="space-y-2 mt-10 md:mt-0 md:space-y-4 lg:max-w-2xl">
          <h2 className="text-4xl text-center lg:text-left md:text-5xl font-semibold">
            Manifesto
          </h2>
          <div className="space-y-10 text-lg">
            <p>
              Straight from prehistory
              <br /> From the jungles to Gen Z<br /> A sabre survivor
              <br /> A provider for the future
              <br /> Everything you gain
              <br /> Living in the blockchain
            </p>
            <p>
              Once a fierce creature
              <br /> Now a universal feature
              <br /> The face to launch a thousand tokens
              <br /> Join his revolution
              <br /> Be part of the solution
              <br /> A virtual landscape to escape
              <br />A web3 metaverse shape
            </p>
            <p>
              Become its giant tooth:
              <br /> Solid gold truth
              <br /> The proof of change begins
              <br /> There’s no mystery to win… <br />
              Making history is in.
            </p>
          </div>
        </div>
        <figure className="hidden lg:inline-block">
          <img src={hero} alt="" />
        </figure>
      </div>
    </section>
  );
}

export default Manifesto;
