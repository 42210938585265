import React from "react";
import SingleArt from "../components/SingleArt";
import OneImageSlider from "../components/OneImageSlider";

function Artwork() {
  return (
    <section className="px-8 md:max-w-6xl py-20 mx-auto w-full" id="artwork">
      <div className="hidden md:block">
        <SingleArt />
      </div>
      <div className="block md:hidden">
        <OneImageSlider />
      </div>

      <div className="space-y-8 flex flex-col items-center mt-10">
        <h3 className="text-center text-base md:text-2xl">
          One Big Cat to Rule All Thugs And Apes
        </h3>
        <p className="text-justify md:text-center text-sm md:text-lg">
          A long time ago, in a Metaverse far away lived the king of beasts. The
          Smilodon was a Lion on steroids, a Pro Wrestler, and not a runner.
          Today, this long-toothed tiger is back, risen from the ashes and full
          of hunger for total domination. Infamous for its cunning stratagems,
          the Smilodon is known far and wide as the Sneaky Saber Tooth.
          Something’s different this time. With eyes set on the metaverse and
          the physical world, the Sneaky Saber Tooth is ready to conquer all
          arenas and show the world what it’s made of. Beware of its mighty
          roar!
        </p>
      </div>
    </section>
  );
}

export default Artwork;
