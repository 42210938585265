import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import mask from "../img/mask.png";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Test() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <section className="px-8 md:max-w-6xl py-28 mx-auto w-full flex flex-col items-center">
      <div className="flex flex-col items-center">
        <img src={mask} alt="" />
        <h2 className="text-3xl md:text-5xl -mt-28">Roadmap</h2>
      </div>
      <div className="flex flex-col items-center justify-between w-full">
        <div className="mt-10">
          <Box sx={{ width: "100%" }}>
            <Box className="flex items-center justify-center">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  className="tab tab1"
                  label="The Hype Sale"
                  {...a11yProps(0)}
                />
                <Tab
                  className="tab tab2"
                  label="The Utility"
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <div>
                <h2 className="text-2xl md:text-4xl mt-20 italic font-semibold">
                  <span className="text-purple">Episode 1:</span>
                  Phase of SellOut
                </h2>
                <div className="py-20 flex flex-col space-y-2">
                  <div className="w-1 h-24 bg-white shadow-md inset-0 line"></div>
                  {/* single */}
                  <div className="flex space-x-5">
                    <div>
                      <div className="bg-white rounded-full h-5 w-5 -ml-2"></div>
                      <div className="w-1 h-full bg-white shadow-md inset-0 mt-2"></div>
                    </div>
                    <div className="p-3 md:p-6 -mt-8">
                      <h2 className="text-xl md:text-3xl text-primary font-bold text-purple pt-1">
                        Phase 1: 1st 50% SellOut
                      </h2>
                      <p className="text-sm p-3">
                        Phase 1 of the Sneaky Sabor Tooth SellOut starts from
                        the first pre-sale and ends at the 50% sales milestone.
                        Here’s what the legendary tiger has planned for you:
                      </p>
                      <ul className="text-white p-5 leading-6 space-y-12 list-disc">
                        <li>
                          <span className="text-lg font-semibold">
                            Whale Roullete <br />
                          </span>
                          Any holder with more than 20 NFTs will become a whale.
                          The system will then automatically pull a winner from
                          all whales. The chosen whale will be rewarded with ONE
                          Bored Mutant Ape for free.
                        </li>
                        <li>
                          <span className="text-lg font-semibold">
                            Diamond Roulette <br />
                          </span>
                          An exclusive draw for holders with more than 10 NFTs.
                          Not one but FIVE winners will get Bored Ape
                          Kennels—one per each!
                        </li>
                        <li>
                          <span className="text-lg font-semibold">
                            Gold Roulette <br />
                          </span>
                          Golden opportunity for gold members. Hold 5 NFTs and
                          you may become one of the 10 lucky winners to get a
                          Hape Prime each.
                        </li>
                        <li>
                          <span className="text-lg font-semibold">
                            Holders Roulette <br />
                          </span>
                          Let’s raise the stakes by rewarding not one, not 10,
                          but 30 randomly selected NFT holders with a Cool Pets
                          NFT. Everyone will be a part of the holders’ roulette!
                          1 Lucky Wallet Will Receive an Actual Physical NFT
                          Necklace/Chain Any holder with more than 20 NFTs will
                          become a whale. The system will then automatically
                          pull a winner from all whales. The chosen whale will
                          be rewarded with ONE Bored Mutant Ape for free.
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* single */}
                  <div className="flex space-x-5">
                    <div>
                      <div className="bg-white rounded-full mt-8 h-5 w-5 -ml-2"></div>
                      <div className="w-1 h-full bg-white shadow-md inset-0 mt-2 line2"></div>
                    </div>
                    <div className="p-3 md:p-6">
                      <h2 className="text-xl md:text-3xl text-primary font-bold text-purple pt-1">
                        Phase 2: Next 50% SellOut
                      </h2>
                      <ul className="text-white py-4 leading-6 space-y-4">
                        <p className="text-base">
                          Upon welcoming/rewarding the newest members of the
                          tribe of the Phase 1, Smilodon will unravel the
                          mysteries of this second phase sellOut and its
                          rewards.
                        </p>
                        <p className="text-base">
                          Phase 2 will be even more ambitious, daring, and
                          exhilarating than phase 1. However, this phase is
                          reserved for those worthy of its knowledge.
                        </p>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div>
                <h2 className="text-2xl md:text-4xl mt-20 italic font-semibold">
                  <span className="text-purple">Episode 2:</span> Our Tribe
                </h2>

                <div className="py-20 flex flex-col space-y-2">
                  <div className="w-1 h-24 bg-white shadow-md inset-0 line"></div>
                  {/* single */}
                  <div className="flex space-x-5">
                    <div>
                      <div className="bg-white rounded-full h-5 w-5 -ml-2"></div>
                      <div className="w-1 h-full bg-white shadow-md inset-0 mt-2"></div>
                    </div>
                    <div className="p-3 md:p-6 -mt-8">
                      <h2 className="text-xl md:text-3xl text-primary font-bold  text-purple pt-1">
                        Tight-Knit Tribe
                      </h2>
                      <p className="text-sm p-3">
                        To lead is to serve those who follow you. As a strategic
                        leader, the Smilodon knows the importance of nurturing a
                        tight-knit tribe where everyone can work towards a
                        fairer and brighter future. Here’s how this will happen.
                      </p>
                      <ul className="text-white p-5 leading-6 space-y-12 list-disc">
                        <li>
                          <span className="text-lg font-semibold">
                            The Saber Tooth Council <br />
                          </span>
                          With dedicated and exclusive community hangout spots,
                          ranging from Discord to other venues, the tribe will
                          always get a say in the future direction of their
                          favorite NFT collection.
                        </li>
                        <li>
                          <span className="text-lg font-semibold">
                            Music Concerts & Video <br />
                          </span>
                          What’s a family without some fun? With virtual music
                          concerts exclusively for the pleasure of holders
                          planned, the Smilodon is never shy to party, and all
                          the tribe is invited. There’s also a brand
                          collaboration music video in the pipeline. Unleash
                          your inner music fanatic by joining the tribe. An
                          exclusive draw for holders with more than 10 NFTs. Not
                          one but FIVE winners will get Bored Ape Kennels—one
                          per each!
                        </li>
                        <li>
                          <span className="text-lg font-semibold">
                            Merchandise
                            <br />
                          </span>
                          Show off your membership of this tribe of majestic
                          tigers through exclusive merchandise available for
                          holders. Your love for the Sneaky Saber Tooth will
                          transcend the limits of NFTs and jump into your
                          physical world.
                        </li>
                      </ul>
                    </div>
                  </div>

                  {/* single */}
                  <div className="flex space-x-5">
                    <div>
                      <div className="bg-white rounded-full h-5 w-5 -ml-2"></div>
                      <div className="w-1 h-full bg-white shadow-md inset-0 mt-2"></div>
                    </div>
                    <div className="p-3 md:p-6 -mt-8">
                      <h2 className="text-xl md:text-3xl text-primary font-bold text-purple  pt-1">
                        Multi-Realm Domination
                      </h2>
                      <p className="text-sm p-3">
                        From the physical world to the blockchain to the
                        metaverse, the Smilodon won’t stop until the tribe
                        conquers all realms of reality. Here’s how the Sneaky
                        Saber Tooth will achieve this:
                      </p>
                      <ul className="text-white p-5 leading-6 space-y-12 list-disc">
                        <li>
                          <span className="text-lg font-semibold">
                            Metaverse Clubhouse <br />
                          </span>
                          Parts of the NFT pre-sale proceeds will go towards
                          buying land in popular metaverse projects. This will
                          be then developed into a stunning clubhouse with some
                          of the most fascinating facilities available for all
                          members. Connect with the community, have fun, laugh a
                          little and live a little—all in an exclusive metaverse
                          NFT Clubhouse.
                        </li>
                        <li>
                          <span className="text-lg font-semibold">
                            Metaverse Token Utility <br />
                          </span>
                          Members of the tribe will be able to use their tokens
                          to upgrade equipment, acquire wearables, and even
                          change the name of their Saber Tooth.
                        </li>
                        <li>
                          <span className="text-lg font-semibold">
                            Merchandise
                            <br />
                          </span>
                          Show off your membership of this tribe of majestic
                          tigers through exclusive merchandise available for
                          holders. Your love for the Sneaky Saber Tooth will
                          transcend the limits of NFTs and jump into your
                          physical world.
                        </li>
                      </ul>
                    </div>
                  </div>

                  {/* single */}
                  <div className="flex space-x-5">
                    <div>
                      <div className="bg-black rounded-full h-5 w-5 -ml-2"></div>
                      <div className="w-1 h-full bg-white shadow-md inset-0 mt-2"></div>
                    </div>
                    <div className="p-3 md:p-6 -mt-8">
                      <h2 className="text-xl md:text-3xl text-primary font-bold text-purple  pt-1">
                        Shape up the Path
                      </h2>
                      <p className="text-sm p-3">
                        Uplifting the financial future of tribe members is one
                        of the biggest priorities. Here’s how the Smilodon will
                        provide fair earning opportunities to the community:
                      </p>
                      <ul className="text-white p-5 leading-6 space-y-12 list-disc">
                        <li>
                          <span className="text-lg font-semibold">
                            Staking Rewards <br />
                          </span>
                          Holders will get the opportunity to stake their NFTs
                          and earn reward tokens in return. Holding becomes a
                          lot easier when you earn passive returns for it—up to
                          10 years from now!
                        </li>
                        <li>
                          <span className="text-lg font-semibold">
                            Exclusive Deals <br />
                          </span>
                          Lucrative virtual land offers in the metaverse and
                          sought-after deals from our physical experts in the
                          US, UK, UAE, and beyond. You’ll always get exclusive
                          access to ROI-positive opportunities from all over the
                          world, both virtual and physical.
                        </li>
                        <li>
                          <span className="text-lg font-semibold">
                            Investment Incubator
                            <br />
                          </span>
                          Diversify your portfolio beyond decentralized assets
                          by discovering profitable investment opportunities in
                          the UK with our investment incubator. You’re sure to
                          get attractive merger and acquisition deals here.
                          Nothing less!
                        </li>
                        <li>
                          <span className="text-lg font-semibold">
                            Education Portal
                            <br />
                          </span>
                          This dedicated portal will contain both courses and
                          podcasts on crypto trading. This will be made in
                          collaboration with some of the most successful
                          traders. The goal is to bring you field-tested and
                          up-to-date strategies.
                        </li>
                        <li>
                          <span className="text-lg font-semibold">
                            P2E Gaming
                            <br />
                          </span>
                          The P2E game that’s under development will allow users
                          to bring their NFT saber tooth to life and have a
                          blast in virtual adventures. At the same time, this
                          game will rival titles like Axie infinity in terms of
                          offering players the opportunity to monetize their
                          passion and earn rewards with their skills.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
          </Box>
        </div>
      </div>
    </section>
  );
}

export default Test;
