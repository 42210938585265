import React, { Component } from "react";
import Slider from "react-slick";
import Slider1 from "../img/Slider1.png";
import Slider2 from "../img/Slider2.png";
import Slider3 from "../img/Slider3.png";
import Slider4 from "../img/Slider4.png";
import Slider5 from "../img/Slider5.png";
import Slider6 from "../img/Slider6.png";
import Slider7 from "../img/Slider7.png";
import Slider8 from "../img/Slider8.png";

export default class AutoPlayMethods extends Component {
  constructor(props) {
    super(props);
    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
  }
  play() {
    this.slider.slickPlay();
  }
  pause() {
    this.slider.slickPause();
  }
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
    };
    return (
      <div className="">
        {/* <h2>Auto Play & Pause with buttons</h2> */}
        <Slider ref={(slider) => (this.slider = slider)} {...settings}>
          <div>
            <figure>
              <img className="custom" src={Slider1} alt="" />
            </figure>
          </div>
          <div>
            <figure>
              <img className="custom" src={Slider2} alt="" />
            </figure>
          </div>
          <div>
            <figure>
              <img className="custom" src={Slider3} alt="" />
            </figure>
          </div>
          <div>
            <figure>
              <img className="custom" src={Slider4} alt="" />
            </figure>
          </div>
          <div>
            <figure>
              <img className="custom" src={Slider5} alt="" />
            </figure>
          </div>
          <div>
            <figure>
              <img className="custom" src={Slider6} alt="" />
            </figure>
          </div>
          <div>
            <figure>
              <img className="custom" src={Slider7} alt="" />
            </figure>
          </div>
          <div>
            <figure>
              <img className="custom" src={Slider8} alt="" />
            </figure>
          </div>
        </Slider>
        {/* <div style={{ textAlign: "center" }}>
          <button className="button" onClick={this.play}>
            Play
          </button>
          <button className="button" onClick={this.pause}>
            Pause
          </button>
        </div> */}
      </div>
    );
  }
}
