import banner from "../img/banner.png";

function Banner() {
  return (
    <section className="px-8 md:max-w-6xl py-14 mx-auto w-full">
      <div className="flex items-center space-x-4">
        <figure className="hidden md:inline-flex">
          <img src={banner} alt="" />
        </figure>
        <div>
          <div className="space-y-5 text-2xl md:text-5xl">
            <h2>Perks of owning a</h2>
            <h2>Sneaky Saber Tooth</h2>
          </div>
          <ul className="list-decimal text-sm space-y-4 p-6">
            <li> Get Royalty of 5% in the NFT (Applicable to first holders)</li>
            <li>Passive Income by Token</li>
            <li>Metaverse Real Estate and Play-to-earn opportunity</li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Banner;
