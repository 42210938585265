import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";

import mask from "./img/mask.png";

function Claim() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const [ownedTokens, setTokens] = useState(0);
  const [totalTokens, setTotalTokens] = useState(0);
  const [feedback, setFeedback] = useState(`Click to claim your token.`);

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 4,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimAll = () => {
    blockchain.smartContract.methods
      .claimTokens()
      .send({
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(`successful`);
        dispatch(fetchData(blockchain.account));
      });
  };

  const claimTokens = (event) => {
    event.preventDefault();
    const tokenID = event.target.tokenId.value;

    blockchain.smartContract.methods
      .claimById(tokenID)
      .send({
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(`successful`);
        dispatch(fetchData(blockchain.account));
      });
  };

  const fetchToken = async () => {
    var tokens = await blockchain.smartContract.methods
      .walletOfOwner(blockchain.account)
      .call();
    setTokens(tokens.length);

    // get total claimable tokens

    var calculatedValues = await blockchain.smartContract.methods
      .calculateTokens(blockchain.account)
      .call();
    setTotalTokens(calculatedValues);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
      fetchToken();
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <div className="py-20">
      <>
        {blockchain.account === "" || blockchain.smartContract === null ? (
          <div className="flex flex-col items-center justify-center w-full py-40">
            <h3 className="text-white text-center font-primary text-sm md:text-xl md:tracking-wider">
              Connect wallet to claim your Token.
            </h3>

            <button
              className="bg-purple  text-black font-bold px-10 py-4 rounded-md font-primary tracking-wider mt-16"
              onClick={(e) => {
                e.preventDefault();
                dispatch(connect());
                getData();
              }}
            >
              CONNECT
            </button>
            {blockchain.errorMsg !== "" ? (
              <>
                <h2 class="text-center text-white mt-5 font-semibold font-Opensans">
                  {blockchain.errorMsg}
                </h2>
              </>
            ) : null}
          </div>
        ) : (
          <div className="flex flex-col items-center w-full space-y-10 md:space-y-20">
            <div className="flex flex-col items-center">
              <img src={mask} alt="" />
              <h2 className="text-2xl md:text-4xl -mt-28"> {feedback}</h2>
            </div>

            <div className="py-7">
              <p className="text-sm md:text-xl text-center">
                IF YOU HAVE ANY CLAIMABLE $LGN TOKENS FROM THIS WALLET,
                <br /> IT WILL BE SHOWN BELOW
              </p>
            </div>

            <div className="flex items-center space-x-14 md:space-x-20">
              <button className="font-semibold text-white border-2 border-white w-28 px-2 md:w-32 h-14 text-base md:text-lg rounded-lg">
                {ownedTokens} TOKENS
              </button>

              <button
                className="bg-blue text-white h-14  font-semibold text-lg rounded-lg px-5 border-2 border-white"
                onClick={(e) => {
                  e.preventDefault();
                  claimAll();
                }}
              >
                CLAIM ALL
              </button>
            </div>
            <span className="text-white font-primary text-base md:text-2xl">
              Total Claimable tokens: {totalTokens}
            </span>

            <div className="flex justify-center">
              <div class="flex items-center font-primary text-white tracking-wider md:font-semibold  md:space-x-20 md:text-xl">
                <form onSubmit={claimTokens} className="flex space-x-6">
                  <div className="">
                    <input
                      className="bg-black px-2 border-white border-2 outline-none py-3 rounded-md"
                      name="tokenId"
                      placeholder="Enter ID"
                    />
                  </div>
                  <div>
                    <input
                      className="bg-blue text-white h-14  font-semibold text-lg rounded-lg px-5 border-2 border-white cursor-pointer"
                      type="submit"
                      value="CLAIM"
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="space-y-8 px-5 mt-5">
              <p className="text-xs md:text-lg text-center">
                You can hit -Claim All- to claim your $LGN tokens OR you can
                enter the token IDs for your NFT, For Example: 1, 2, 55, 234
                etc.
              </p>
              <p className="text-xs md:text-lg text-center">
                NOTE: Please enter ID's exactly like this.Your reward will be
                available to claim.Claim will cost GAS.
              </p>
            </div>
          </div>
        )}
      </>
    </div>
  );
}

export default Claim;
