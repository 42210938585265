import mask from "../img/mask.png";
import rarity from "../img/rarity.png";

function Rarity() {
  return (
    <section className="px-8 py-10 mx-auto w-full">
      <div className="flex flex-col items-center">
        <img src={mask} alt="" />
        <h2 className="text-3xl md:text-5xl -mt-28">Check our Epic Sabers</h2>
        <p className="text-justify md:text-center text-sm md:text-lg mt-10 md:max-w-3xl">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lacus,
          elit odio adipiscing netus. Vitae, enim vitae at dolor ultrices nunc,
          in augue dignissim. Fusce volutpat ornare amet pellentesque.{" "}
        </p>
      </div>
      <div className="grid grid-cols-3 md:grid-cols-7 gap-4 mt-10">
        <figure>
          <img src={rarity} alt="" />
        </figure>
        <figure>
          <img src={rarity} alt="" />
        </figure>
        <figure>
          <img src={rarity} alt="" />
        </figure>
        <figure>
          <img src={rarity} alt="" />
        </figure>
        <figure>
          <img src={rarity} alt="" />
        </figure>
        <figure>
          <img src={rarity} alt="" />
        </figure>
        <figure>
          <img src={rarity} alt="" />
        </figure>
        <figure>
          <img src={rarity} alt="" />
        </figure>
        <figure>
          <img src={rarity} alt="" />
        </figure>
        <figure>
          <img src={rarity} alt="" />
        </figure>
        <figure>
          <img src={rarity} alt="" />
        </figure>
        <figure>
          <img src={rarity} alt="" />
        </figure>
        <figure>
          <img src={rarity} alt="" />
        </figure>
        <figure>
          <img src={rarity} alt="" />
        </figure>
      </div>
    </section>
  );
}

export default Rarity;
