import mask from "../img/mask.png";
import React, { useState } from "react";
import faqData from "../handlers/faqData";
import { FaPlus, FaMinus } from "react-icons/fa";

function Faq() {
  const [data, setData] = useState(faqData);
  const [clicked, setClicked] = useState(false);

  const toggle = (index) => {
    if (clicked === index) {
      //if clicked question is already active closed it
      return setClicked(null);
    }
    setClicked(index);
  };

  return (
    <section className="px-8 md:max-w-6xl py-12 mx-auto w-full" id="faq">
      <div className="flex flex-col items-center">
        <img src={mask} alt="" />
        <h2 className="text-3xl md:text-5xl -mt-28">
          Frequently Asked Questions
        </h2>
      </div>
      <div className="mt-28 grid grid-cols-1">
        {data.map((item, index) => (
          <div key={item.id} onClick={() => toggle(index)} className="">
            <div className="mb-6 md:mb-10 ">
              <div className="cursor-pointer">
                <div className="flex justify-between items-center border border-gray md:py-5 py-4 px-4 md:px-5">
                  <h2 className="text-white text-sm md:text-lg">
                    {item.question}
                  </h2>
                  <span className="text-white font-bold">
                    {clicked === index ? (
                      <FaMinus className="text-base md:text-lg" />
                    ) : (
                      <FaPlus className="text-base md:text-lg" />
                    )}
                  </span>
                </div>
                {clicked === index ? (
                  <div className="mt-4 text-white  md:py-3 py-2 px-2 md:px-3 font-semibold text-sm md:text-base">
                    {item.answer}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Faq;
