import React from "react";
import Hero from "./Hero";
import Community from "./Community";
import Faq from "./Faq";
import Roadmap from "./Roadmap";
import Banner from "./Banner";
import Team from "./Team";
import Rarity from "./Rarity";
import Test from "./Test";
import Rise from "./Rise";
import Artwork from "./Artwork";

function Home() {
  return (
    <>
      <Hero />
      {/* <Banner /> */}
      <Rise />
      {/* <Rarity /> */}
      <Artwork />
      <Community />
      {/* <Roadmap /> */}
      {/* <Test /> */}
      <Team />
      <Faq />
    </>
  );
}

export default Home;
