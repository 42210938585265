import React from "react";
import { Link } from "react-router-dom";

function Rise() {
  return (
    <section className="px-8 md:max-w-6xl pt-32 py-20 mx-auto w-full" id="rise">
      <div className="space-y-10 flex flex-col items-center">
        <h2 className="text-center text-3xl md:text-5xl">The Rise</h2>
        <p className="text-justify md:text-center text-sm md:text-lg">
          The Sneaky Saber Tooth is a riveting 3D collection of 10,000 NFT
          tigers living on the Ethereum blockchain. Equal parts quirky and equal
          parts ferocious, these ancient cats are back from the dead and there’s
          no stopping them. Their mission? Conquering the virtual and the
          physical realms. Keep on scrolling to unravel their many mysteries.
        </p>
        <a
          href="/manifesto"
          className="uppercase bg-blue px-10 py-4 rounded-md inline-block font-semibold border border-white"
        >
          Our Manifesto
        </a>
      </div>
    </section>
  );
}

export default Rise;
